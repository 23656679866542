<template>
  <div class="basic-data-list">
    <query-view
      ref="myQueryView"
      name="factoryPositionCode"
      :value.sync="baseFormModel.factoryPositionCode"
      placeholder="lang.gles.stock.pleaseInputFactoryPositionCode"
      :query-list="moreQueryData"
      :width="'50%'"
      @baseSearch="handleBaseSearch"
      @moreSearch="handleMoreSearch"
    />
    <el-card class="table-card">
      <div class="operator-wrapper">
        <el-button type="primary" :disabled="!selectValue.length" @click="freezeOrThaw('freeze')">
          {{ $t('lang.gles.base.freeze') }}
        </el-button>
        <el-button :disabled="!selectValue.length" @click="freezeOrThaw('thaw')">
          {{ $t('lang.gles.stock.thaw') }}
        </el-button>
        <!-- 列设置 -->
        <column-config :list.sync="columnConfigList" />
      </div>
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableExtendConfig"
        @inventoryDetails="inventoryDetails"
        @pageChange="pageChange"
        @selection-change="val => (selectValue = val)"
      >
        <template #goodsPositionMove="{ row }">
          <span v-if="row.goodsPositionCodeMovable">
            <el-tag v-for="(item,index) in row.goodsPositionCodeMovable.split(',')" :key="index" effect="plain" type="info">
              {{ item }}
            </el-tag>
          </span>
        </template>
      </m-table>
    </el-card>
    <!-- 执行结果弹窗 -->
    <operate-result ref="operateResult" @getList="doSearch(baseFormModel)" />
    <!-- 库存明细 -->
    <inventory-details ref="inventoryDetails" />
  </div>
</template>
<script>
import ColumnConfig from '@/components/columnConfig'
import QueryView from '@/components/moreQuery/queryView.vue'
import InventoryDetails from './components/inventoryDetails.vue'
import MTable from '@/libs_sz/components/MTable/MTable'
import { getMoreQueryFormData, getSearchTableItem, getOperateTableData } from './data'
import * as types from '@/store/mutationTypes'
import listMixins from '@/mixins/listMixins'
import inventoryMixins from '../../inventoryMixins'
import { mapState } from 'vuex'
import OperateResult from './components/operateResult.vue'
import dayjs from 'dayjs'
export default {
  name: 'LocationInventoryList',
  components: {
    ColumnConfig,
    QueryView,
    MTable,
    InventoryDetails,
    OperateResult
  },
  mixins: [listMixins, inventoryMixins],
  data() {
    return {
      tableData: [],
      containerTypeList: [],
      baseFormModel: {
        factoryPositionCode: '',
        type: 2 // 库存类型：1-固定货位库存 2-库位库存
      },
      formModel: {
        type: 2
      },
      pageData: {
        currentPage: 1,
        pageSize: 10,
        recordCount: 0
      },
      selectValue: [],
      visible: false
    }
  },
  computed: {
    ...mapState('base', ['gradeStatusList', 'statusFlagList']),
    moreQueryData: function() {
      return getMoreQueryFormData(this, { ...this.formModel })
    },
    operateResultData() {
      return getOperateTableData(this, {})
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      return getSearchTableItem(this)
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter((item, i) => item.name === this.columnConfigList[i]?.name && this.columnConfigList[i]?.isShow)
      }
      return list
    }
  },
  async created() {
    this.tableExtendConfig = {
      ...this.tableExtendConfig,
      checkBox: true,
      operateWidth: '100px',
      operate: [
        {
          event: 'inventoryDetails',
          label: this.$t('lang.gles.stock.stockDetails')
        }
      ]
    }
    this.initSelectList()
    this.doSearch(this.baseFormModel)
  },
  methods: {
    initSelectList() {
      this.$store.dispatch('base/getGradeStatusList')
      if (!this.statusFlagList?.length) {
        this.$store.dispatch('base/getStatusFlagList')
      }
      this.getFactoryList()
      this.getWorkshopList()
      this.getWarehouseList()
      this.getWarehouseAreaList()
      this.getFactoryPositionList()
      this.getMoveContainerCodeList()
      this.getTurnoverContainerCodeList()
      this.getMoveGoodsPositionList({ goodsPositionType: 'movingGoodsPosition' })
      this.getMaterialCodeList()
    },
    // 查询
    async doSearch(params, api = 'getFixedLocationInventoryListByCode') {
      if (api === 'getFixedLocationInventoryListByCode') {
        params.containerArchivesCode = params.goodsPositionCode
      }
      try {
        const { data, code } = await this.$httpService(
          this.$apiStore.inventoryList(api, this.pageData),
          params
        )
        if (code) return
        this.$store.commit(types.IS_LOADING, false)
        const {
          recordList = [],
          currentPage,
          pageSize,
          totalRecordCount: recordCount
        } = data || {}
        this.pageData = { currentPage, pageSize, recordCount }
        this.tableData = recordList || []
      } catch (error) {
        this.$store.commit(types.IS_LOADING, false)
      }
    },
    // 冻结 / 解冻
    async freezeOrThaw(operate) {
      const selectData = this.selectValue
      if (selectData.length < 1) {
        this.$message({
          type: 'error',
          message: this.$t('lang.gles.common.uncheckedDataToBeDeleted')
        })
        return
      }
      const goodsInfoList = selectData.map(item => { return { id: item.id, goodsPositionCode: item.goodsPositionCode } })
      console.log(operate, '---', goodsInfoList)
      const params = {
        goodsInfoList, type: 2, containerTypePatternType: 'fixedContainer'
      }
      // 冻结、解冻后 弹出操作结果
      operate === 'freeze' ? params.operate = 1 : params.operate = 2
      const { data, code } = await this.$httpService(
        this.$apiStore.inventoryList('freezeOrThaw'),
        params
      )
      if (code) return
      console.log(data, 'data')
      this.$refs.operateResult.init(data)
    },
    /**
     * 基础查询
     */
    handleBaseSearch(data) {
      this.doSearch({ ...data, type: 2 })
    },
    // 更多查询
    handleMoreSearch(data) {
      if (data.entryDate && data.entryDate.length > 0) {
        data.entryDateStart = dayjs(data.entryDate[0]).format('YYYY-MM-DD HH:mm:ss')
        data.entryDateEnd = dayjs(data.entryDate[1]).format('YYYY-MM-DD HH:mm:ss')
      }
      delete data.entryDate
      this.formModel = { ...data }
      this.doSearch({ ...data, type: 2 }, 'getFixedLocationInventoryList')
    },
    /**
     * 更多查询重置方法
     * @param {*} data
     */
    handleReset(data) {
      // this.formModel = {
      //   type: 2
      // }
      // 更新级联下拉选项
      this.getWorkshopList()
      this.getWarehouseList()
      this.getWarehouseAreaList()
    },
    // 库存明细弹窗
    inventoryDetails({ row }) {
      const options = {
        gradeStatusList: this.gradeStatusList,
        materialCodeList: this.materialCodeList,
        materialList: this.materialList
      }
      this.$refs.inventoryDetails.init({ ...row }, options)
    }
  }
}
</script>
