
import { t } from '@/libs_sz/locale'
import myTransform from '@/libs_sz/utils/transform'

const getFormatter = (data, value) => {
  const getValue = myTransform.arrToObject(data, 'value', 'label')[value] || value
  return getValue === 'null' || getValue === 0 ? '' : getValue
}

const getValue = value => (value ? String(value) : String(value) === '0' ? '0' : '')

const statusDict = [{
  label: t('lang.gles.base.containerStatusNormal'),
  value: 0
},
{
  label: t('lang.gles.stock.occupied'),
  value: 10
},
{
  label: t('lang.gles.stock.frozen'),
  value: 20
}
]

export const getMoreQueryFormData = (that, formModel) => {
  // const factoryPositionCodeList = that.factoryPositionList.map(item => { return { label: item.factoryPositionCode, value: item.value } }) || []
  const moreQueryData = [
    // 库位编码
    {
      name: 'factoryPositionId',
      span: 12,
      component: 'RemoteSelect',
      label: that.$t('lang.gles.base.factoryPositionCode'),
      clearable: true,
      labelText: 'factoryPositionCode',
      // data: that.factoryPositionList || []
      mchange(val, item, cb) {
        val ? cb({ factoryPositionName: that.factoryPositionList.find(item => item.value === val).label }) : cb({ factoryPositionName: '' })
      }
    },
    // 库位名称
    {
      name: 'factoryPositionName',
      span: 12,
      component: 'RemoteSelect',
      label: that.$t('lang.gles.base.factoryPositionName'),
      clearable: true,
      // options: that.factoryPositionList || [],
      mchange(val, item, cb) {
        val ? cb({ factoryPositionId: that.factoryPositionList.find(item => item.value === val).value }) : cb({ factoryPositionId: '' })
      }
    },
    // 移动容器编码
    {
      name: 'containerArchivesIdMovable',
      span: 12,
      component: 'RemoteSelect',
      queryType: 'moveContainerCodeList',
      label: that.$t('lang.gles.common.moveContainerCode'),
      clearable: true
      // data: that.moveContainerCodeList || []
    },
    // 移动容器货位
    {
      name: 'goodsPositionIdMovable',
      span: 12,
      component: 'RemoteSelect',
      queryType: 'moveGoodsPositionList',
      label: that.$t('lang.gles.common.moveContainerGoodsPosition'),
      clearable: true
      // data: that.moveGoodsPositionList || []
    },
    // 所属工厂
    {
      name: 'factoryId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.base.belongingFactory'),
      clearable: true,
      filterable: true,
      data: that.factoryList || [],
      mchange: that.handleFactoryChange
    },
    // 所属车间
    {
      name: 'workshopId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.base.belongingWorksop'),
      clearable: true,
      filterable: true,
      data: that.workshopList || [],
      mchange: that.handleWorkshopChange,
      disabled: !!that.$refs.myQueryView?.$refs.myForm?.$refs.moreQueryData?.formModel.warehouseId
    },
    // 所属仓库
    {
      name: 'warehouseId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.base.belongingWarehouse'),
      clearable: true,
      filterable: true,
      data: that.warehouseList || [],
      mchange: that.handleWarehouseChange,
      // 车间、仓库互斥
      disabled: !!that.$refs.myQueryView?.$refs.myForm?.$refs.moreQueryData?.formModel.workshopId
    },
    // 所属库区
    {
      name: 'warehouseAreaId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.base.belongingWarehouseArea'),
      clearable: true,
      filterable: true,
      data: that.warehouseAreaList || []
    },
    // 周转容器编码
    {
      name: 'turnoverContainerId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.orderManage.turnoverContainerId'),
      clearable: true,
      data: that.turnoverContainerCodeList || []
    },
    // 物料编码
    {
      name: 'materialId',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.material.materialCode'),
      clearable: true,
      data: that.materialCodeList || [],
      mchange(val, item, cb) {
        val ? cb({ materialName: that.materialList.find(item => item.value === val).value }) : cb({ materialName: '' })
      }
    },
    // 物料名称
    {
      name: 'materialName',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.material.materialName'),
      clearable: true,
      data: that.materialList || [],
      mchange(val, item, cb) {
        val ? cb({ materialId: that.materialCodeList.find(item => item.value === val).value }) : cb({ materialId: '' })
      }
    },
    // 物料条码
    {
      name: 'materialBarcode',
      span: 12,
      component: 'elInput',
      label: that.$t('lang.gles.material.materialBarcode'),
      clearable: true
    },
    // 入库日期
    {
      name: 'entryDate',
      span: 12,
      component: 'elDatePicker',
      label: that.$t('lang.gles.base.storeDate'),
      type: 'datetimerange',
      rangeSeparator: '-',
      clearable: true
    },
    // 库位状态
    {
      name: 'stockStatus',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.base.factoryPositionStatus'),
      clearable: true,
      data: statusDict || []
    },
    // 等级状态
    {
      name: 'gradeStatus',
      span: 12,
      component: 'elSelect',
      label: that.$t('lang.gles.common.gradeStatus'),
      clearable: true,
      data: that.gradeStatusList || []
    },
    // 批次号
    {
      name: 'batchNumber',
      span: 12,
      component: 'elInput',
      label: that.$t('lang.gles.stock.batchNumber'),
      clearable: true
    }
  ]
  return moreQueryData
}
// 查询列表列表
export const getSearchTableItem = (that, options) => {
  const searchTableItem = [
    // 库位编码
    {
      prop: 'factoryPositionId',
      label: that.$t('lang.gles.base.factoryPositionCode'),
      formatter(row, column) {
        return that.factoryPositionList.find(item => item.value === row[column])?.factoryPositionCode
      },
      isShow: true
    },
    // // 库位名称
    {
      prop: 'factoryPositionId',
      label: that.$t('lang.gles.base.factoryPositionName'),
      formatter(row, column) {
        return that.factoryPositionList.find(item => item.value === row[column])?.label
      },
      isShow: true
    },
    // 移动货位
    {
      prop: 'goodsPositionCodeMovable',
      label: that.$t('lang.gles.base.movingGoodsPosition'),
      minWidth: 246,
      slotName: 'goodsPositionMove',
      isShow: true,
      showOverflowTooltip: false
    },
    // 所属工厂
    {
      prop: 'factoryId',
      label: that.$t('lang.gles.base.belongingFactory'),
      formatter(row, column) {
        return getFormatter(that.factoryList, row[column])
      },
      isShow: true
    },
    // 所属车间
    {
      prop: 'workshopId',
      label: that.$t('lang.gles.base.belongingWorksop'),
      formatter(row, column) {
        return getFormatter(that.workshoplist, row[column])
      },
      isShow: true
    },
    // 所属仓库
    {
      prop: 'warehouseId',
      label: that.$t('lang.gles.base.belongingWarehouse'),
      formatter(row, column) {
        return getFormatter(that.warehouselist, row[column])
      },
      isShow: true
    },
    // 所属库区
    {
      prop: 'warehouseAreaId',
      label: that.$t('lang.gles.base.belongingWarehouseArea'),
      formatter(row, column) {
        return getFormatter(that.warehouseArealist, row[column])
      },
      isShow: true
    },
    // 库存数量
    {
      prop: 'stockNum',
      label: that.$t('lang.gles.stock.stockNum'),
      minWidth: 84,
      formatter(row, column) {
        return getValue(row[column])
      },
      isShow: true
    },
    // 占用数量
    {
      prop: 'occupyNum',
      label: that.$t('lang.gles.stock.occupyNum'),
      minWidth: 84,
      formatter(row, column) {
        return getValue(row[column])
      },
      isShow: true
    },
    // 库位状态
    {
      prop: 'stockStatus',
      label: that.$t('lang.gles.base.factoryPositionStatus'),
      minWidth: 84,
      formatter(row, column) {
        return getFormatter(statusDict, row[column])
      },
      isShow: true
    },
    // 库位启用状态
    {
      prop: 'factoryPositionUsableFlag',
      label: that.$t('lang.gles.base.usableFlag'),
      minWidth: 84,
      formatter(row, column) {
        return getFormatter(that.statusFlagList, row[column])
      },
      isShow: true
    },
    // 冻结数量
    {
      prop: 'freezeNum',
      label: that.$t('lang.gles.stock.frozenNum'),
      minWidth: 84,
      formatter(row, column) {
        return getValue(row[column])
      },
      isShow: true
    },
    // 可用数量
    {
      prop: 'availableNum',
      label: that.$t('lang.gles.stock.availableNum'),
      minWidth: 84,
      formatter(row, column) {
        return getValue(row[column])
      },
      isShow: true
    }
  ]
  searchTableItem.forEach((item) => (item.showOverflowTooltip = item.showOverflowTooltip ?? true))
  return searchTableItem
}
// 操作结果列表
export const getOperateTableData = (that, options) => {
  const operateTableItems = [
    // 库位编码
    {
      prop: 'factoryPositionCode',
      label: that.$t('lang.gles.orderManage.factoryPositionId')
    },
    // 移动货位
    {
      prop: 'goodsPositionCodeMovable',
      label: that.$t('lang.gles.base.movingGoodsPosition')
    },
    // 执行结果
    {
      prop: 'result',
      label: that.$t('lang.gles.stock.enforcementResult'),
      slotName: 'resultStatus'
    },
    // 备注
    {
      prop: 'remark',
      label: that.$t('lang.gles.strategy.remark'),
      formatter(row, column) {
        return that.$t(row[column])
      }
    }
  ]
  return operateTableItems
}
export const getMoveOperateTableData = (that, options) => {
  const operateTableItems = [
    // 移动货位
    {
      prop: 'goodsPositionCodeMovable',
      label: that.$t('lang.gles.base.movingGoodsPosition')
    },
    // 库位编码
    {
      prop: 'factoryPositionCode',
      label: that.$t('lang.gles.base.factoryPositionCode')
    },
    // 执行结果
    {
      prop: 'result',
      label: that.$t('lang.gles.stock.enforcementResult'),
      slotName: 'moveContainerResult'
    },
    // 备注
    {
      prop: 'remark',
      label: that.$t('lang.gles.strategy.remark'),
      formatter(row, column) {
        return t(row[column])
      }
    }
  ]
  return operateTableItems
}
// 按容器查看
export const getContainerTableItems = (that, options) => {
  const containerTableItems = [
    // 移动货位
    {
      prop: 'goodsPositionCode',
      label: that.$t('lang.gles.base.movingGoodsPosition')
    },
    // 移动容器编码
    {
      prop: 'containerArchivesId',
      label: that.$t('lang.gles.common.moveContainerCode'),
      formatter(row, column) {
        return getFormatter(that.containerCodeList, row[column])
      }
    },
    // 移动容器类型
    {
      prop: 'containerTypeId',
      label: that.$t('lang.gles.common.moveContainerType'),
      formatter(row, column) {
        return getFormatter(that.containerTypeList, row[column])
      }
    },
    // 移动容器名称
    // {
    //   prop: 'containerTypeName',
    //   label: that.$t('lang.gles.common.moveContainerName'),
    //   formatter(row, column) {
    //     return getFormatter(options.station_name_list, row[column])
    //   }
    // },
    // 移动货位序号
    {
      prop: 'goodsPositionNum',
      label: that.$t('lang.gles.common.moveGoodsPositionNum')
    },
    // 库存数量
    {
      prop: 'stockNum',
      label: that.$t('lang.gles.stock.stockNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 占用数量
    {
      prop: 'occupyNum',
      label: that.$t('lang.gles.stock.occupyNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 冻结数量
    {
      prop: 'freezeNum',
      label: that.$t('lang.gles.stock.frozenNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 可用数量
    {
      prop: 'availableNum',
      label: that.$t('lang.gles.stock.availableNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 库位状态
    {
      prop: 'stockStatus',
      label: that.$t('lang.gles.base.factoryPositionStatus'),
      formatter(row, column) {
        return getFormatter(statusDict, row[column])
      }
    }
  ]
  containerTableItems.forEach((item) => (item.showOverflowTooltip = true))
  return containerTableItems
}
// childTableItem
export const getChildTableItem = (that, options) => {
  const childTableItems = [
    // 周转容器编码
    {
      prop: 'containerArchivesId',
      label: that.$t('lang.gles.orderManage.turnoverContainerId'),
      formatter(row, column) {
        return getFormatter(that.containerCodeList, row[column])
      }
    },
    // 周转容器类型
    {
      prop: 'containerTypeId',
      label: that.$t('lang.gles.material.turnoverContainerType'),
      formatter(row, column) {
        return getFormatter(that.containerTypeList, row[column])
      }
    },
    // 库存数量
    {
      prop: 'stockNum',
      label: that.$t('lang.gles.stock.stockNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 占用数量
    {
      prop: 'occupyNum',
      label: that.$t('lang.gles.stock.occupyNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 冻结数量
    {
      prop: 'freezeNum',
      label: that.$t('lang.gles.stock.frozenNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 可用数量
    {
      prop: 'availableNum',
      label: that.$t('lang.gles.stock.availableNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    }
  ]
  return childTableItems
}
// getGrandSonTableItem
export const getGrandSonTableItem = (that, options) => {
  const grandsonTableItems = [
    // 物料编码
    {
      prop: 'materialId',
      label: that.$t('lang.gles.material.materialCode'),
      formatter(row, column) {
        return getFormatter(options.materialCodeList, row[column])
      }
    },
    // 物料名称
    {
      prop: 'materialId',
      label: that.$t('lang.gles.material.materialName'),
      formatter(row, column) {
        return getFormatter(options.materialList, row[column])
      }
    },
    // 物料条码
    {
      prop: 'materialBarcode',
      label: that.$t('lang.gles.material.materialBarcode')
    },
    // 物料规格
    // {
    //   prop: 'materialSpec',
    //   label: that.$t('物料规格'),
    //   formatter(row, column) {
    //     return getValue(row[column])
    //   }
    // },
    // 基本单位
    {
      prop: 'basicUnit',
      label: that.$t('lang.gles.common.basicUnit'),
      formatter(row, column) {
        return getFormatter(options.basicUnit_dict, row[column])
      }
    },
    // 计量数量
    {
      prop: 'measuringNum',
      label: that.$t('lang.gles.stock.measuredNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 计量单位
    {
      prop: 'measuringUnit',
      label: that.$t('lang.gles.common.measureUnit'),
      formatter(row, column) {
        return getFormatter(options.measuringUnit_dict, row[column])
      }
    },
    // 物料朝向
    {
      prop: 'materialToward',
      label: that.$t('lang.gles.orderManage.materialToward'),
      formatter(row, column) {
        return getFormatter(options.materialTowardList, row[column])
      }
    },
    // 生产日期
    // {
    //   prop: 'proDate',
    //   label: t('生产日期'),
    //   formatter: { type: 'time' }
    // },
    // 入库日期
    {
      prop: 'entryDate',
      label: that.$t('lang.gles.base.storeDate'),
      minWidth: 150,
      formatter: { type: 'time' }
    },
    // 库存数量
    {
      prop: 'stockNum',
      label: that.$t('lang.gles.stock.stockNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 占用数量
    {
      prop: 'occupyNum',
      label: that.$t('lang.gles.stock.occupyNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 冻结数量
    {
      prop: 'freezeNum',
      label: that.$t('lang.gles.stock.frozenNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 可用数量
    {
      prop: 'availableNum',
      label: that.$t('lang.gles.stock.availableNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 批次号
    {
      prop: 'batchNumber',
      label: that.$t('lang.gles.stock.batchNumber')
    },
    // 等级状态
    {
      prop: 'gradeStatus',
      label: that.$t('lang.gles.common.gradeStatus'),
      formatter(row, column) {
        return getFormatter(options.gradeStatusList, row[column])
      }
    },
    // 在库时长
    {
      prop: 'holdTime',
      label: that.$t('lang.gles.base.warehouseArea.storageTimeInLibrary')
    },
    // 批属性
    {
      prop: 'batchProperty',
      label: that.$t('lang.gles.material.batchProperty'),
      minWidth: 64,
      slotName: 'batchPropertySlot'
    }
  ]
  grandsonTableItems.forEach((item) => (item.showOverflowTooltip = true))
  return grandsonTableItems
}
// 按物料查看
export const getMaterialTableItems = (that, options) => {
  const materialTableItems = [
    // 物料编码
    {
      prop: 'materialId',
      label: that.$t('lang.gles.material.materialCode'),
      formatter(row, column) {
        return getFormatter(options.materialCodeList, row[column])
      }
    },
    // 所属容器
    {
      prop: 'containerArchivesCode',
      label: that.$t('lang.gles.stock.belongingContainer')
    },
    // 所属货位
    {
      prop: 'goodsPositionCode',
      label: that.$t('lang.gles.stock.belongingGoodsPosition')
    },
    // 物料名称
    {
      prop: 'materialId',
      label: that.$t('lang.gles.material.materialName'),
      formatter(row, column) {
        return getFormatter(options.materialList, row[column])
      }
    },
    // 物料条码
    {
      prop: 'materialBarcode',
      label: that.$t('lang.gles.material.materialBarcode')
    },
    // 物料规格
    // {
    //   prop: 'materialSpec',
    //   label: that.$t('物料规格')
    // },
    // 基本单位
    {
      prop: 'basicUnit',
      label: that.$t('lang.gles.common.basicUnit'),
      formatter(row, column) {
        return getFormatter(options.basicUnit_dict, row[column])
      }
    },
    // 计量数量
    {
      prop: 'measuringNum',
      label: that.$t('lang.gles.stock.measuredNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 计量单位
    {
      prop: 'measuringUnit',
      label: that.$t('lang.gles.common.measureUnit'),
      formatter(row, column) {
        return getFormatter(options.measuringUnit_dict, row[column])
      }
    },
    // 物料朝向
    {
      prop: 'materialToward',
      label: that.$t('lang.gles.orderManage.materialToward'),
      formatter(row, column) {
        return getFormatter(options.materialTowardList, row[column])
      }
    },
    // 入库日期
    {
      prop: 'entryDate',
      label: that.$t('lang.gles.base.storeDate'),
      minWidth: 150,
      formatter: { type: 'time' }
    },
    // 库存数量
    {
      prop: 'stockNum',
      label: that.$t('lang.gles.stock.stockNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 占用数量
    {
      prop: 'occupyNum',
      label: that.$t('lang.gles.stock.occupyNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 冻结数量
    {
      prop: 'freezeNum',
      label: that.$t('lang.gles.stock.frozenNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 可用数量
    {
      prop: 'availableNum',
      label: that.$t('lang.gles.stock.availableNum'),
      formatter(row, column) {
        return getValue(row[column])
      }
    },
    // 批次号
    {
      prop: 'batchNumber',
      label: that.$t('lang.gles.stock.batchNumber')
    },
    // 库位状态
    {
      prop: 'stockStatus',
      label: that.$t('lang.gles.base.factoryPositionStatus'),
      formatter(row, column) {
        return getFormatter(statusDict, row[column])
      }
    },
    // 等级状态
    {
      prop: 'gradeStatus',
      label: that.$t('lang.gles.common.gradeStatus'),
      formatter(row, column) {
        return getFormatter(options.gradeStatusList, row[column])
      }
    },
    // 在库时长
    {
      prop: 'holdTime',
      label: that.$t('lang.gles.base.warehouseArea.storageTimeInLibrary')
    },
    // 批属性
    {
      prop: 'batchProperty',
      label: that.$t('lang.gles.material.batchProperty'),
      minWidth: 64,
      slotName: 'batchPropertySlot'
    }
  ]
  materialTableItems.forEach((item) => (item.showOverflowTooltip = true))
  return materialTableItems
}

