<template>
  <div>
    <!-- 主表弹框 -->
    <el-dialog
      :title="$t('lang.gles.stock.stockDetails')"
      :close-on-click-modal="false"
      :visible.sync="visible"
      width="80%"
      @closed="reset"
    >
      <!-- 主表-->
      <div v-if="activeName==='first' && containerTableData.length" class="freezeOrThaw">
        <el-button type="primary" :disabled="!selectValue.length" @click="freezeOrThaw('freeze')">
          {{ $t('lang.gles.base.freeze') }}
        </el-button>
        <el-button :disabled="!selectValue.length" @click="freezeOrThaw('thaw')">
          {{ $t('lang.gles.stock.thaw') }}
        </el-button>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClickTabs">
        <el-tab-pane :label="$t('lang.gles.stock.viewByContainer')" name="first">
          <!-- 有移动容器和移动货位(有二级数据) -->
          <div v-if="containerTableData.length > 0">
            <m-table
              :table-item="containerTableItem"
              :table-data="containerTableData"
              :extend-config="tableExtendConfig"
              @pageChange="pageChange"
              @selection-change="val=> (selectValue = val)"
            >
              <!-- 展开 -->
              <template v-slot:expand="scope">
                <div v-if="scope.row.turnoverContainers" class="child-table">
                  <m-table
                    border
                    size="mini"
                    :table-item="childTableItem"
                    :table-data="scope.row.turnoverContainers || []"
                    :extend-config="childTableExtendConfig"
                  >
                    <template v-slot:expand="scopes">
                      <div class="grandson-table">
                        <m-table
                          border
                          size="mini"
                          :table-item="grandsonTableItem"
                          :table-data="scopes.row.materials || []"
                          :extend-config="grandsonTableExtendConfig"
                        >
                          <template #batchPropertySlot="{ row }">
                            <batch-property-info :grid-data="row" />
                          </template>
                        </m-table>
                      </div>
                    </template>
                  </m-table>
                </div>
                <div v-if="scope.row.materials" class="child-table">
                  <m-table
                    border
                    size="mini"
                    :table-item="grandsonTableItem"
                    :table-data="scope.row.materials || []"
                    :extend-config="grandsonTableExtendConfig"
                  >
                    <template #batchPropertySlot="{ row }">
                      <batch-property-info :grid-data="row" />
                    </template>
                  </m-table>
                </div>
              </template>
            </m-table>
          </div>
          <!-- 有移动容器和无移动货位(有二级数据) -->
          <div v-else-if="noGoodsPositionTableData.length > 0">
            <m-table
              size="mini"
              :table-item="childTableItem"
              :table-data="noGoodsPositionTableData"
              :extend-config="childTableExtendConfig"
            >
              <template v-slot:expand="scopes">
                <div class="grandson-table">
                  <m-table
                    size="mini"
                    :table-item="grandsonTableItem"
                    :table-data="scopes.row.materials || []"
                    :extend-config="grandsonTableExtendConfig"
                  >
                    <template #batchPropertySlot="{ row }">
                      <batch-property-info :grid-data="row" />
                    </template>
                  </m-table>
                </div>
              </template>
            </m-table>
          </div>
          <!-- 无移动容器和移动货位(无二级数据) -->
          <m-table
            v-else
            size="mini"
            :table-item="grandsonTableItem"
            :table-data="noContainerTableData"
            :extend-config="materialTableExtendConfig"
          />
        </el-tab-pane>
        <el-tab-pane :label="$t('lang.gles.stock.viewByMaterial')" name="second">
          <m-table
            :table-item="materialTableItem"
            :table-data="materialTableData"
            :extend-config="materialTableExtendConfig"
          >
            <template #batchPropertySlot="{ row }">
              <batch-property-info :grid-data="row" />
            </template>
          </m-table>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <!-- 执行结果弹窗 -->
    <move-operate-result ref="moveOperateResult" @getDetailList="init(dataSave)" />
  </div>
</template>
<script>
import BatchPropertyInfo from '@/components/batchPropertyInfo/index.vue'
import { getContainerTableItems, getChildTableItem, getGrandSonTableItem, getMaterialTableItems } from '../data'
import { mapState, mapActions } from 'vuex'
import MoveOperateResult from './moveOperateResult.vue'

export default {
  name: 'InventoryDetails',
  components: { MoveOperateResult, BatchPropertyInfo },
  provide: {
    rootPage: {
      isPagination: false
    }
  },
  data() {
    return {
      visible: false,
      dataSave: {},
      activeName: 'first',
      tableExtendConfig: {
        sortNum: true,
        checkBox: true,
        expand: true,
        isSelectionFixed: true
      },
      materialTableExtendConfig: {
        sortNum: true
      },
      formModel: {},
      pageData: {
        currentPage: 1,
        pageSize: 10,
        recordCount: 0
      },
      containerTableData: [],
      noGoodsPositionTableData: [],
      noContainerTableData: [],
      materialTableData: [],
      selectValue: [],
      childTableExtendConfig: {
        sortNum: true,
        expand: true
      },
      grandsonTableExtendConfig: {
        sortNum: true
      },
      options: {}
    }
  },
  computed: {
    ...mapState('base', ['containerTypeList', 'containerCodeList']),
    ...mapState('materialManage', ['basicUnitList', 'measuringUnitList', 'materialTowardList']),
    // tableItem
    containerTableItem() {
      console.log(this.options, 'options')
      return getContainerTableItems(this, this.options)
    },
    childTableItem() {
      return getChildTableItem(this, this.options)
    },
    grandsonTableItem() {
      const options = {
        basicUnit_dict: this.basicUnitList,
        measuringUnit_dict: this.measuringUnitList,
        materialTowardList: this.materialTowardList,
        ...this.options
      }
      return getGrandSonTableItem(this, options)
    },
    materialTableItem() {
      const options = {
        basicUnit_dict: this.basicUnitList,
        measuringUnit_dict: this.measuringUnitList,
        materialTowardList: this.materialTowardList,
        ...this.options
      }
      return getMaterialTableItems(this, options)
    }
  },
  created() {
    if (!this.basicUnitList?.length) {
      this.getBasicUnitList()
    }
    if (!this.measuringUnitList?.length) {
      this.getMeasuringUnitList()
    }
    if (!this.materialTowardList?.length) {
      this.getMaterialToward()
    }
  },
  methods: {
    ...mapActions('materialManage', ['getBasicUnitList', 'getMeasuringUnitList', 'getMaterialToward']),
    init(row, options) {
      this.visible = true
      this.dataSave = row
      if (options) { this.options = options }
      // 初始化容器类型和容器编码数据(避免出现页面查不到新增的数据)
      this.$store.dispatch('base/getContainerTypeList')
      this.$store.dispatch('base/getContainerCodeList')

      this.$nextTick(async() => {
        const params = {
          id: row.id,
          type: 2
        }
        const { data, code } = await this.$httpService(
          this.$apiStore.inventoryList('queryStockDetailByContainer'),
          params
        )
        if (code) return
        if (data.movableContainers) {
          this.commonUnit(data.movableContainers)
          this.containerTableData = data.movableContainers
        }
        if (data.turnoverContainers) {
          this.noGoodsPositionTableData = data.turnoverContainers
        }
        if (data.materials) {
          this.noContainerTableData = data.materials
        }
      })
    },
    async handleClickTabs(tab) {
      // console.log(tab)
      if (tab.index === '1' && !this.materialTableData.length) {
        const params = {
          id: this.dataSave.id,
          type: 2
        }
        try {
          const { data, code } = await this.$httpService(
            this.$apiStore.inventoryList('queryStockDetailByMaterial'),
            params
          )
          if (code) return
          this.commonUnit(data)
          this.materialTableData = data
        } catch (error) {
          console.log(error)
        }
      }
    },
    // 冻结 / 解冻
    async freezeOrThaw(operate) {
      const selectData = this.selectValue
      if (selectData.length < 1) {
        this.$message({
          type: 'error',
          message: this.$t('lang.gles.common.uncheckedDataToBeDeleted')
        })
        return
      }
      const goodsInfoList = selectData.map(item => { return { id: item.id, goodsPositionCode: item.goodsPositionCode } })
      console.log(operate, '---', goodsInfoList)
      const params = {
        goodsInfoList, type: 2, containerTypePatternType: 'moveContainer'
      }
      // 冻结、解冻后 弹出操作结果
      operate === 'freeze' ? params.operate = 1 : params.operate = 2
      const { data, code } = await this.$httpService(
        this.$apiStore.inventoryList('freezeOrThaw'),
        params
      )
      if (code) return
      console.log(data, 'data')
      this.$refs.moveOperateResult.init(data)
    },
    reset() {
      this.containerTableData = []
      this.noGoodsPositionTableData = []
      this.materialTableData = []
      this.noContainerTableData = []
      this.activeName = 'first'
    },
    // 页码改变
    pageChange(pageData) {
      this.pageData = { ...this.pageData, ...pageData }
      this.doSearch({ ...this.formModel, ...this.pageData }, true)
    },
    // 递归在库时长 添加 单位
    commonUnit(params) {
      const that = this
      if (params.constructor === Array) {
        params && params.forEach(item => {
          for (const key in item) {
            if (item[key] && item[key].constructor === Array) {
              that.commonUnit(item[key])
            } else {
              if (key === 'holdTime') {
                if (item[key]) {
                  item[key] += 'min'
                }
              }
            }
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.freezeOrThaw{
  position: absolute;
  right:30px;
  z-index: 1000;
}
.child-table {
  padding: 6px 16px;
}
.grandson-table {
  padding: 6px 16px;
}
</style>
